.menus {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 30px 70px;

  @media only screen and (max-width: 991px) {
    padding: 50px 10px 70px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  background: #fff;
  border: 1px solid rgba(#5688d5, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 991px) {
    padding: 30px 10px;
  }
}

.title {
  display: flex;
  justify-content: center;

  h2 {
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 576px) {
    gap: 15px;
  }
}

.item {
  position: relative;
  width: 100%;
  border: 1px solid rgba(#5688d5, 0.1);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(#5688d5, 0.07);
  transition: all 0.2s linear;
  min-height: 200px;
  text-decoration: none;

  @media only screen and (max-width: 576px) {
    min-height: 150px;
  }

  &_bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &_content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(#000, 1) 0%,
      rgba(#000, 0.7),
      50%,
      rgba(#000, 0.1) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 15px 20px;

    @media only screen and (max-width: 576px) {
      padding: 10px 14px;
      gap: 10px;
    }
  }

  &_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    // background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 576px) {
      width: 40px;
      height: 40px;
    }

    img {
      width: 70%;
      object-fit: contain;
    }
  }

  &_title {
    font-size: clamp(12px, 3vw, 16px);
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: rgba(#fff, 1);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(#5688d5, 0.15);
  }
}
